import { configureStore } from '@reduxjs/toolkit';
import { surveysApi } from './surveys.api';
import { usersApi } from './users.api';
import { usersGroupApi } from './usersGroup.api';
import { interviewApi } from './interview.api';
import { reportsApi } from './reports.api';
import { permissionsApi } from './permissions';
import { pdfService } from './pdfService';

export const store = configureStore({
  reducer: {
    [surveysApi.reducerPath]: surveysApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [interviewApi.reducerPath]: interviewApi.reducer,
    [usersGroupApi.reducerPath]: usersGroupApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [pdfService.reducerPath]: pdfService.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      surveysApi.middleware,
      usersApi.middleware,
      interviewApi.middleware,
      reportsApi.middleware,
      permissionsApi.middleware,
      usersGroupApi.middleware,
      pdfService.middleware
    )
});

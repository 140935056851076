import { SUPPORTED_LANGUAGES, LANGUAGES_DISPLAY_NAME } from '../components/appConstants';

function useLanguageSelectOptions() {
  const options = Object.values(SUPPORTED_LANGUAGES).map((language) => {
    return {
      value: language,
      text: LANGUAGES_DISPLAY_NAME[language]
    };
  });

  return {
    options
  };
}

export default useLanguageSelectOptions;

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark'
};

export const ROUTES = {
  SETTINGS: {
    index: '/settings'
  },
  ADMIN: {
    index: '/admin',
    USERS: '/admin/users',
    USERS_GROUP: '/admin/usersgroup',
    SURVEYS: '/admin/surveys',
    REPORT_CREATION: '/admin/create-report'
  },
  SURVEYS: {
    index: '/surveys',
    WEB_SURVEY: '/surveys/websurvey',
    TELEPHONE_SURVEY: '/surveys/telephone'
  },
  RESULT: {
    index: '/result',
    RESULT_ITEM: '/result/:id'
  },
  HOME: {
    index: '/'
  }
};

export const SUPPORTED_LANGUAGES = {
  EN: 'en-US',
  DE: 'de-DE'
};

export const LANGUAGES_DISPLAY_NAME = {
  [SUPPORTED_LANGUAGES.EN]: 'EN',
  [SUPPORTED_LANGUAGES.DE]: 'DE'
};

import React, { useLayoutEffect, useState, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';

import { THEME } from '../components/appConstants';
import { ThemeContext } from './themeContext';
import { Loader } from '../components';
import { ThemeConfig } from '../models/models';

type ThemeProviderType = {
  children: React.ReactNode;
};

const THEME_KEY = 'theme';

function ThemeProvider({ children }: ThemeProviderType) {
  const [theme, setTheme] = useState(localStorage.getItem(THEME_KEY) || THEME.LIGHT);
  const [themeConfig, setThemeConfig] = useState<ThemeConfig>();
  useLayoutEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem(THEME_KEY, theme);
  }, [theme]);

  const getThemeConfig = async () => {
    let error;
    try {
      const data = await import(`../themes/${window.location.hostname}`);
      setThemeConfig(data.default as ThemeConfig);
    } catch (e) {
      error = e;
    }

    if (error) {
      const data = await import(`../themes/${localStorage.getItem('themeTest') || 'peakA'}`);
      setThemeConfig(data.default as ThemeConfig);
    }
  };

  useEffect(() => {
    getThemeConfig();
  }, []);

  if (!themeConfig) return <Loader />;

  return (
    <ThemeContext.Provider value={{ theme, setTheme, themeConfig }}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider
          theme={
            themeConfig[theme as keyof ThemeConfig] ?? themeConfig[THEME.LIGHT as keyof ThemeConfig]
          }>
          {children}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;

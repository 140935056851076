import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { store } from './store';
import ThemeProvider from './providers/themeProvider';
import CurrentUserProvider from './providers/currentUserProvider';
import UserService from './services/userService';
import { Loader } from './components';

import './index.css';

const AppLoader = () => {
  return <Loader size={48} color="secondary" fullScreen />;
};

const AppWrapper = ({ error }: { error?: string }) => {
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider>
          <CurrentUserProvider>
            <App />
          </CurrentUserProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);
const renderApp = (error?: string) => {
  root.render(<AppWrapper error={error} />);
};

root.render(<AppLoader />);
UserService.initKeycloak(renderApp);

import { useContext } from 'react';

import { CurrentUserContext } from '../providers/currentUserContext';

export default function useCurrentUserData() {
  const { permissions, reports, user_iam_info, id } = useContext(CurrentUserContext);

  return {
    id,
    user_iam_info,
    reports,
    permissions
  };
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Response, SurveyById, SurveyList } from '../models/surveys';
import UserService from '../services/userService';
import { reportsApi } from './reports.api';
import { createBaseQueryWithReAuth } from './utils';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_URL}/surveys`,
  prepareHeaders: (headers) => {
    const token = UserService.getToken();

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

export const surveysApi = createApi({
  reducerPath: 'surveys/api',
  refetchOnMountOrArgChange: true,
  baseQuery: createBaseQueryWithReAuth(baseQuery),
  endpoints: (build) => ({
    surveysList: build.query<SurveyList, void>({
      query: () => ({
        method: 'GET',
        url: '/'
      })
    }),
    survey: build.query<SurveyById, string>({
      query: (id) => ({
        method: 'GET',
        url: `/${id}`
      })
    }),
    response: build.mutation<{ message: string }, Response>({
      query: (body) => ({
        method: 'POST',
        url: '/response',
        body: JSON.stringify(body)
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(reportsApi.util.invalidateTags(['ASSIGNED_REPORTS']));
        } catch {
          /* empty */
        }
      }
    })
  })
});

export const { useResponseMutation, useSurveysListQuery, useSurveyQuery } = surveysApi;

export const SURVEY_TYPES = {
  TELEPHONE_SURVEY: 2,
  WEB_SURVEY: 1
};

export const SURVEY_STATUSES = {
  NOT_STARTED: 'not_started',
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished'
};

import React, { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Logout } from '@carbon/icons-react';
import { Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { SelectProps } from '@mui/material';

import UserService from '../services/userService';
import { Loader, Select } from '.';
import useLanguageSelectOptions from '../hooks/useLanguageSelectOptions';
import ThemeToggle from './themeToggle';
import { useUpdateUserLanguageMutation } from '../store/users.api';

import { topBarMenuStyles } from './componentStyles';

type TopBarMenuType = {
  onClose: () => void;
  anchorEl: HTMLDivElement | null;
};

const TopBarMenu = ({ onClose, anchorEl }: TopBarMenuType) => {
  const [updateLanguage] = useUpdateUserLanguageMutation();
  const { classes } = topBarMenuStyles();
  const { i18n, t } = useTranslation();
  const { options } = useLanguageSelectOptions();
  const [isLoading, setIsLoading] = useState(false);

  const changeLanguage: SelectProps['onChange'] = async (e) => {
    const value = e.target.value as string;

    try {
      setIsLoading(true);
      await updateLanguage({ language: value }).unwrap();
      await i18n.changeLanguage(value);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const handleLanguageChange: SelectProps['onChange'] = (event, child) => {
    changeLanguage(event, child);
  };

  const avatarMenuItems = useMemo(() => {
    return [
      {
        icon: Logout,
        id: 1,
        label: t('COMMON.LOGOUT_LABEL'),
        link: '/logout',
        onClick: (e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          UserService.doLogout();
        }
      }
    ];
  }, [i18n.language]);

  return (
    <>
      {isLoading && <Loader fullScreen size={48} />}
      <Menu
        classes={{ paper: classes.menu__wrapper }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        transformOrigin={{
          vertical: -40,
          horizontal: 'center'
        }}
        data-testid="menu">
        <div className={classes.itemsWrapper}>
          <Select
            withDefault={false}
            value={i18n.language}
            onChange={handleLanguageChange}
            className={classes.select}
            name="language"
            size={1}
            options={options}
          />
          <div className={classes.links}>
            {avatarMenuItems.map((item) => (
              <MenuItem onClick={onClose} key={item.id} className={classes.menuItems}>
                <Link to={item.link} onClick={item.onClick}>
                  <div className={`classes.menu__${item.label}`}>
                    <div className={classes.item}>
                      {item.icon && <item.icon />}
                      {item.label}
                    </div>
                  </div>
                </Link>
              </MenuItem>
            ))}
          </div>
          <ThemeToggle />
        </div>
      </Menu>
    </>
  );
};

export default TopBarMenu;

import React, { forwardRef } from 'react';
import cn from 'classnames';
import { Button as MuiButton, ButtonProps } from '@mui/material';
import { buttonStyles } from './componentStyles';

export type ButtonType = Omit<ButtonProps, 'size'> & {
  size?: 1 | 2;
  icon?: React.ReactElement | null;
  children?: React.ReactElement | string;
  className?: string;
};

const DEFAULT_SIZE = 1;

const Button = forwardRef<HTMLButtonElement, ButtonType>(
  ({ icon, className, size, children, ...restProps }, ref) => {
    const { classes } = buttonStyles();

    return (
      <MuiButton
        ref={ref}
        color="primary"
        variant="contained"
        {...restProps}
        className={cn(classes.muiButton, classes[`size${size || DEFAULT_SIZE}`], className)}>
        <div className={classes.button}>
          {icon && (
            <span className={cn(classes.icon, [{ [classes.iconMargin]: Boolean(children) }])}>
              {icon}
            </span>
          )}
          <span>
            <span className={classes.text}>{children}</span>
          </span>
        </div>
      </MuiButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;

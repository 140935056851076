import React, { useEffect, useState } from 'react';
import { CurrentUserContext } from './currentUserContext';

import { Loader, ErrorView } from '../components';
import { useCurrentUserQuery } from '../store/users.api';
import { PermissionsNameType } from '../models/permissions';
import { useAssignedReportsQuery } from '../store/reports.api';
import initI18n from '../i18n';

type Props = {
  children: React.ReactNode;
};

function CurrentUserProvider({ children }: Props) {
  const { data, isSuccess: isUserFetched, isError: isUserError } = useCurrentUserQuery();
  const {
    data: reports = [],
    isSuccess: isReportFetched,
    isError: isReportsError
  } = useAssignedReportsQuery();
  const [language, setLanguage] = useState('');

  useEffect(() => {
    if (data?.language) {
      initI18n(data?.language).then(() => {
        setLanguage(data?.language);
      });
    }
  }, [data?.language]);

  const isError = isReportsError || isUserError;
  const errorTexts = !language
    ? {
        title: 'Error',
        description: 'Something went wrong'
      }
    : {};

  if (!isError && ((!isReportFetched && !isUserFetched) || !language)) {
    return <Loader fullScreen size={48} />;
  }

  if (isError || !data) return <ErrorView {...errorTexts} />;

  const permissions =
    data?.permissions?.reduce(
      (current, acc) => {
        current[acc.name] = true;

        return current;
      },
      {} as Record<PermissionsNameType, boolean>
    ) || ({} as Record<PermissionsNameType, boolean>);

  const user_iam_info = data.user_iam_info;

  return (
    <CurrentUserContext.Provider value={{ permissions, reports, user_iam_info, id: data.id }}>
      {children}
    </CurrentUserContext.Provider>
  );
}

export default CurrentUserProvider;

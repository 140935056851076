import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import UserService from '../services/userService';
import { PermissionsNameType } from '../models/permissions';
import { createBaseQueryWithReAuth } from './utils';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_URL}/permissions/`,
  prepareHeaders: (headers) => {
    const token = UserService.getToken();

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

export const permissionsApi = createApi({
  reducerPath: 'permissions/api',
  refetchOnMountOrArgChange: true,
  baseQuery: createBaseQueryWithReAuth(baseQuery),
  endpoints: (build) => ({
    permissionsList: build.query<{ name: PermissionsNameType; id: string }[], void>({
      query: () => ({
        url: `/`
      })
    })
  })
});

export const { usePermissionsListQuery, useLazyPermissionsListQuery } = permissionsApi;

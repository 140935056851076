import React from 'react';
import { Warning } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

import EmptyView from './emptyView';

type Props = {
  title?: string;
  description?: string;
};
export default function ErrorView({ title, description }: Props) {
  const [t] = useTranslation();

  return (
    <EmptyView
      title={title || t('COMMON.ERROR')}
      description={description || t('COMMON.ERROR_TEXT')}
      icon={Warning}
    />
  );
}

import React from 'react';
import cn from 'classnames';

import { titleStyles } from './componentStyles';

type Props = {
  icon?: React.ReactElement;
  text: string | React.ReactNode;
  size?: 1 | 2 | 3;
  className?: string;
  iconClassName?: string;
};

export default function Title({ icon, text, className, iconClassName, size = 1 }: Props) {
  const { classes } = titleStyles();

  return (
    <div className={cn(classes.title, classes[`size_${size}`], className)}>
      {icon && <div className={cn(classes.icon, iconClassName)}>{icon}</div>}
      {text}
    </div>
  );
}

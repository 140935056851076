import React from 'react';
import cn from 'classnames';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import useRoutesList from '../hooks/useRoutesList';

import { breadcrumbsStyles } from './componentStyles';
import { ROUTES } from './appConstants';

export default function Breadcrumbs() {
  const routes = useRoutesList();
  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: [
      `${ROUTES.SURVEYS.TELEPHONE_SURVEY}/:id`,
      `${ROUTES.SURVEYS.WEB_SURVEY}/:id`,
      `${ROUTES.RESULT.RESULT_ITEM}`
    ]
  });
  const { classes } = breadcrumbsStyles();

  return (
    <>
      {breadcrumbs.map(({ breadcrumb, match }, index) => {
        return (
          <span
            key={match.pathname}
            className={cn({ [classes.active]: index === breadcrumbs.length - 1 })}>
            {breadcrumb}
            {index < breadcrumbs.length - 1 && ' / '}
          </span>
        );
      })}
    </>
  );
}

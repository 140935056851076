import { createContext } from 'react';
import { Report } from '../models/reports';
import { User } from '../models/users';

type ValueType = {
  permissions: Record<string, boolean>;
  reports: Report[];
  user_iam_info: User['user_iam_info'];
  id: User['id'];
};

export const CurrentUserContext = createContext<ValueType>({
  id: '',
  permissions: {},
  user_iam_info: {} as User['user_iam_info'],
  reports: []
});

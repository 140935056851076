import loadable from '@loadable/component';

export const SurveySelectionPage = loadable(
  () => import('./surveySelectionPage/surveySelectionPage')
);
export const WebSurveyPage = loadable(() => import('./webSurveyPage/webSurveyPage'));
export const UsersPage = loadable(() => import('./adminPage/adminPageUserPage/adminPageUserPage'));
export const UserGroupPage = loadable(
  () => import('./adminPage/adminPageUserGroup/adminPageUserGroup')
);
export const ResultPage = loadable(() => import('./resultPage/resultPage'));
export const SettingsPage = loadable(() => import('./settingsPage/settingsPage'));
export const TelephoneSurveyPage = loadable(
  () => import('./telephoneSurveyPage/telephoneSurveyPage')
);
export const SurveysPage = loadable(() => import('./adminPage/adminPageSurveys/adminPageSurveys'));
export const ReportCreationPage = loadable(
  () => import('./adminPage/adminPageReportCreation/adminPageReportCreation')
);
export const HomePage = loadable(() => import('./homePage/homePage'));

import React from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';

import Select, { SelectType } from './select';

import { formStyles } from './componentStyles';

type SelectWrapperType = FieldProps & SelectType;

const SelectWrapper = ({ field, className, ...restProps }: SelectWrapperType) => {
  const { classes } = formStyles();

  return (
    <div className={className}>
      <Select {...field} {...restProps} />
      <div className={classes.error}>
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};
export default function FormSelect(props: SelectType) {
  return <Field component={SelectWrapper} {...props} />;
}

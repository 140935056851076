import React from 'react';
import cn from 'classnames';

import { Button, ButtonType } from '.';

import { actionStyles } from './componentStyles';

type Props = {
  className?: string;
  actionsProps: {
    submit?: ButtonType;
    cancel?: ButtonType;
  };
};

export default function Actions({ actionsProps, className }: Props) {
  const { classes } = actionStyles();

  return (
    <div className={cn(classes.actions, className)}>
      {actionsProps.cancel && <Button {...actionsProps.cancel} />}
      {actionsProps.submit && <Button {...actionsProps.submit} />}
    </div>
  );
}

import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from '@carbon/icons-react';
import cn from 'classnames';
import {
  MenuItem,
  Select as MuiSelect,
  InputLabel,
  SelectProps,
  SelectChangeEvent
} from '@mui/material';

import { selectStyles } from './componentStyles';

type OptionType = {
  value: string | number;
  text: string;
};

type CustomSelectProps = {
  onChange?: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
  options: OptionType[];
  name?: string;
  label?: string;
  className?: string;
  size?: 1 | 2;
  variant?: SelectProps['variant'];
  withDefault?: boolean;
};

export type SelectType = Omit<SelectProps, 'onChange' | 'size' | 'variant'> & CustomSelectProps;

export default function Select({
  variant = 'standard',
  withDefault = true,
  options,
  name,
  className,
  label,
  onChange,
  size = 2,
  multiple,
  placeholder,
  ...restProps
}: SelectType) {
  const { classes } = selectStyles();
  const defaultState = multiple ? [...((restProps.value as []) || [])] : restProps.value || '';
  const [value, setValue] = useState<unknown>(defaultState);
  const [t] = useTranslation();
  const handleChange: SelectProps['onChange'] = (event, child: ReactNode) => {
    onChange?.(event, child);
    setValue(event.target.value);
  };

  const renderValue =
    multiple && Array.isArray(value) && !value.length
      ? () => {
          return (
            <span className={classes.emptyText}>{placeholder || t('COMMON.CHOOSE_OPTION')}</span>
          );
        }
      : undefined;

  return (
    <div className={cn(classes.wrapper, className)}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <MuiSelect
        variant={variant}
        {...restProps}
        className={cn(classes.muiSelect, classes[`size${size}`])}
        name={name}
        placeholder={placeholder}
        multiple={multiple}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        value={value}
        displayEmpty
        renderValue={renderValue}
        onChange={handleChange}
        inputProps={{
          classes: {
            icon: classes.icon
          }
        }}
        IconComponent={ChevronDown}>
        {withDefault && !multiple && (
          <MenuItem value="">
            <span className={classes.emptyText}>{placeholder || t('COMMON.CHOOSE_OPTION')}</span>
          </MenuItem>
        )}
        {options.map(({ value, text }) => {
          return (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </div>
  );
}

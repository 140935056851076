import React, { ChangeEvent } from 'react';
import cn from 'classnames';
import { Asleep, Awake } from '@carbon/icons-react';
import { Fade, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import IosToggle from './iosToggle';
import useThemeContext from '../hooks/useThemeContext';
import { THEME } from './appConstants';
import { Button } from '.';

import { themeToggleStyles } from './componentStyles';

const ThemeToggle = () => {
  const { classes } = themeToggleStyles();
  const [t] = useTranslation();

  const { theme, setTheme } = useThemeContext();

  const toggleTheme = (e: ChangeEvent<HTMLInputElement>) => {
    const newTheme = e?.target?.checked ? THEME.LIGHT : THEME.DARK;
    setTheme(newTheme);
  };

  const handleLightClick = () => setTheme(THEME.LIGHT);
  const handleDarkClick = () => setTheme(THEME.DARK);

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip
      }}
      title={t('COMMON.THEME_SWITCHER') || ''}
      placement="left-start"
      TransitionComponent={Fade}>
      <div>
        <div className={classes.label}>{t('COMMON.SWITCHER_LABEL')}</div>
        <div className={classes.toggleWrapper}>
          <Button
            onClick={handleDarkClick}
            variant="text"
            className={classes.button}
            icon={<Asleep className={cn(classes.icon, classes.asleep)} />}
          />
          {theme === 'light' && (
            <IosToggle checked={true} onChange={toggleTheme} name="theme-toggle" />
          )}
          {theme === 'dark' && (
            <IosToggle checked={false} onChange={toggleTheme} name="theme-toggle" />
          )}
          <Button
            onClick={handleLightClick}
            className={classes.button}
            icon={<Awake className={cn(classes.icon, classes.awake)} />}
            variant="text"
          />
        </div>
      </div>
    </Tooltip>
  );
};

export default ThemeToggle;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useCurrentUserData from './useCurrentUserData';
import { ROUTES } from '../components/appConstants';
import { SURVEY_STATUSES } from '../pages/surveySelectionPage/surveySelectionPageConstants';

type Route = {
  id: string;
  name: string;
  withTabIndex: boolean;
  link?: string;
  category?: string;
  children?: Route[];
};

export default function useNavigationList() {
  const [t, i18n] = useTranslation();
  const { permissions, reports } = useCurrentUserData();
  const resultChildren = reports.reduce<Route[]>((acc, currentValue) => {
    if (currentValue.status === SURVEY_STATUSES.FINISHED) {
      acc.push({
        withTabIndex: false,
        id: currentValue.id,
        name: currentValue.name,
        link: `${ROUTES.RESULT.index}/${currentValue.id}`
      });
    }

    return acc;
  }, []);

  return useMemo(() => {
    const routes: Route[] = [
      {
        id: 'root',
        name: t('COMMON.NAVIGATION.HOME'),
        withTabIndex: true,
        link: ROUTES.HOME.index
      },
      {
        id: 'root4',
        name: t('COMMON.NAVIGATION.SURVEYS'),
        withTabIndex: true,
        link: ROUTES.SURVEYS.index
      }
    ];

    if (resultChildren.length) {
      routes.push({
        id: 'root3',
        name: t('COMMON.RESULT'),
        withTabIndex: true,
        category: '/result',
        children: resultChildren
      });
    }

    if (permissions.administrate) {
      const children = [];

      if (permissions.view_users) {
        children.push({
          id: 'root7',
          name: t('COMMON.NAVIGATION.USERS'),
          withTabIndex: true,
          link: ROUTES.ADMIN.USERS
        });
      }

      if (permissions.view_groups) {
        children.push({
          id: 'root6',
          name: t('COMMON.NAVIGATION.USERS_GROUP'),
          withTabIndex: true,
          link: ROUTES.ADMIN.USERS_GROUP
        });
      }

      routes.push({
        id: 'root2',
        name: t('COMMON.NAVIGATION.ADMIN'),
        withTabIndex: true,
        category: '/admin',
        children: [
          ...children,
          {
            id: 'root8',
            name: t('COMMON.NAVIGATION.SURVEYS'),
            withTabIndex: true,
            link: ROUTES.ADMIN.SURVEYS
          }
        ]
      });
    }

    return routes;
  }, [permissions, i18n.language, resultChildren]);
}

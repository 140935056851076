import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

import { SUPPORTED_LANGUAGES } from './components/appConstants';

export default function initI18n(language: string) {
  return i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: language,
      ns: ['translation'],
      defaultNS: 'translation',
      supportedLngs: Object.values(SUPPORTED_LANGUAGES),
      interpolation: {
        escapeValue: false
      }
    });
}

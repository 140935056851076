import { makeStyles } from 'tss-react/mui';

export const accordionStyles = makeStyles()((theme) => ({
  accordion: {
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(0, 3, 3),
      display: 'inherit'
    },
    '& .MuiAccordionActions-root': {
      padding: theme.spacing(3)
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: theme.spacing(3, 0, 2)
    },
    '& .MuiAccordionSummary-root': {
      padding: theme.spacing(0, 3)
    }
  }
}));

export const actionStyles = makeStyles()((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'right',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  }
}));
export const buttonStyles = makeStyles()((theme) => ({
  muiButton: {
    position: 'relative',
    textTransform: 'initial',
    lineHeight: 1.3,
    minWidth: 0,
    flexGrow: 0,
    flexShrink: 0,
    fontSize: 16,
    marginLeft: theme.spacing(1)
  },
  text: {
    verticalAlign: 'text-top'
  },
  size1: {
    padding: theme.spacing(1)
  },
  size2: {
    padding: theme.spacing(2)
  },
  button: {
    display: 'flex',
    alignItems: 'center'
  },
  iconMargin: {
    marginRight: theme.spacing(1)
  },
  icon: {
    display: 'flex'
  }
}));

export const inputStyles = makeStyles()((theme) => ({
  input: {
    width: '100%',
    borderRadius: theme.dimensions.borderRadiusPanel,
    borderBottom: `1px solid ${theme.customPalette.neutral[400]}`,
    padding: theme.spacing(2),
    backgroundColor: theme.customPalette.neutral[50],
    '& .MuiInputBase-input': {
      height: '1.1876em',
      padding: 0
    },
    '& input::placeholder': {
      color: theme.customPalette.neutral[600]
    },
    '&.Mui-focused': {
      borderBottom: `1px solid ${theme.palette.secondary.main}`
    }
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: 13,
    marginBottom: theme.spacing(1)
  },
  icon: {
    color: theme.customPalette.neutral[600],
    marginRight: theme.spacing(1)
  },
  size1: {
    padding: theme.spacing(1)
  },
  size2: {
    padding: theme.spacing(2)
  }
}));
export const loaderStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  fullScreen: {
    zIndex: 9999,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0
  },
  background: {
    backgroundColor: theme.palette?.background?.default,
    opacity: 0.7,
    zIndex: 9999,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  },
  fullScreenSpinner: {
    zIndex: 9999
  }
}));
export const modalStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      margin: theme.spacing(0, 3)
    }
  },
  backDrop: {
    backgroundColor: 'rgba(217, 217, 217, 50%) !important'
  },
  modal: {
    position: 'relative',
    backgroundColor: theme.customPalette.neutral.main,
    padding: theme.spacing(3, 3, 2),
    borderRadius: theme.dimensions.borderRadiusPanel,
    boxShadow: theme.dimensions.shadowDialog,
    maxHeight: `calc(100% - ${theme.spacing(5)})`,
    overflowY: 'auto'
  },
  actions: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  close: {
    position: 'absolute',
    color: theme.palette.text.primary,
    top: theme.spacing(2),
    right: theme.spacing(1.5)
  }
}));

export const selectStyles = makeStyles()((theme) => ({
  wrapper: {
    borderRadius: theme.dimensions.borderRadiusPanel
  },
  muiSelect: {
    width: '100%',
    backgroundColor: theme.customPalette.neutral[50],
    borderRadius: theme.dimensions.borderRadiusPanel,
    borderBottom: `1px solid ${theme.customPalette.neutral[400]}`,
    '&.MuiInput-underline:before, &.MuiInput-underline:hover:not(.Mui-disabled):before': {
      content: 'none'
    },
    '& .MuiSelect-icon': {
      top: 'unset',
      right: theme.spacing(2)
    },
    '&.MuiInput-underline:after': {
      display: 'none'
    },
    '&.Mui-focused .MuiSelect-select': {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: theme.dimensions.borderRadiusPanel
    }
  },
  size1: {
    '& .MuiSelect-select': {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    '& .MuiSelect-icon': {
      right: theme.spacing(1)
    }
  },
  size2: {
    '& .MuiSelect-select': {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  },
  emptyText: {
    color: theme.customPalette.neutral[500]
  },
  icon: {
    color: theme.palette.text.primary
  },
  label: {
    fontSize: 13,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1)
  }
}));

export const titleStyles = makeStyles()((theme) => ({
  title: {
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center'
  },
  size_1: {
    fontSize: 20
  },
  size_2: {
    fontSize: 24
  },
  size_3: {
    fontSize: 30
  }
}));

const NAVIGATION_WIDTH = 240;
const NAVIGATION_MARGIN_INDEX = 4;

export const appLayoutStyles = makeStyles()((theme) => {
  return {
    '@global': {
      body: {
        backgroundColor: theme.palette.background.default
      }
    },
    application: {
      minHeight: '100vh',
      padding: theme.spacing(3, 4),
      backgroundColor: theme.palette.background.default
    },
    contentWrapper: {
      display: 'flex',
      minHeight: 'calc(100vh - 144px)'
    },
    content: {
      width: '100%',
      maxWidth: `calc(100% - ${NAVIGATION_WIDTH}px - ${theme.spacing(NAVIGATION_MARGIN_INDEX)})`,
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        flexShrink: 0,
        maxWidth: '100%'
      }
    },
    navigation: {
      width: NAVIGATION_WIDTH,
      maxWidth: NAVIGATION_WIDTH,
      marginRight: theme.spacing(NAVIGATION_MARGIN_INDEX)
    }
  };
});

export const breadcrumbsStyles = makeStyles()((theme) => ({
  active: {
    color: theme.palette.secondary.main
  }
}));

export const formStyles = makeStyles()((theme) => ({
  error: {
    color: theme.palette.error.main
  }
}));

export const placeholderStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    color: theme.customPalette.neutral[300],
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    flexGrow: 1
  },
  icon: {
    width: 100,
    height: 100
  },
  title: {
    margin: theme.spacing(4, 0, 2),
    fontSize: 32
  },
  description: {
    fontSize: 20,
    maxWidth: 500
  }
}));

export const sideNavigationStyles = makeStyles()((theme) => ({
  navigation: {
    '& .MuiTreeItem-content': {
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(1)} ${theme.spacing(3)}`,
      flexDirection: 'row-reverse'
    },
    '& .MuiTreeItem-content:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.secondary.main
    },
    '& .MuiTreeItem-label:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.secondary.main
    },
    '& .MuiTreeItem-label, & .MuiTreeItem-label a': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: theme.spacing(2)
      }
    },
    '& .MuiTreeItem-root .Mui-selected.MuiTreeItem-content .MuiTreeItem-label': {
      backgroundColor: theme.customPalette.neutral.main
    },
    '& .MuiTreeItem-root .Mui-selected.MuiTreeItem-content': {
      backgroundColor: theme.customPalette.neutral.main
    },
    display: 'flex',
    position: 'sticky',
    top: theme.spacing(1),
    flexDirection: 'column',
    flexShrink: 0,
    justifyContent: 'space-between',
    background: theme.customPalette.neutral.main,
    maxHeight: 'calc(100vh - 144px)',
    padding: `${theme.spacing(3)} 0`,
    borderRadius: theme.dimensions.borderRadiusPanel,
    boxShadow: theme.dimensions.navigationShadow,
    color: theme.palette.text.primary,
    transition: 'all 1s ease-in-out',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginLeft: `calc(-240px - ${theme.spacing(4)})`,
      opacity: 0
    }
  },
  link: {
    '& > .MuiTreeItem-content': {
      color: theme.palette.text.primary
    }
  },
  active: {
    '& > .MuiTreeItem-content': {
      color: theme.palette.secondary.main
    },
    '& .MuiTreeItem-label': {
      fontWeight: theme.typography.fontWeightBold
    }
  },
  activeCategory: {
    '& > .MuiTreeItem-content': {
      color: theme.palette.secondary.main
    }
  },
  showNav: {
    opacity: 1,
    marginLeft: 0
  }
}));

export const tablePaginationActionStyles = makeStyles()((theme) => ({
  actions: {
    display: 'flex',
    marginLeft: 'auto',
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    '& .MuiButtonBase-root': {
      padding: theme.spacing(1)
    }
  },
  button: {
    color: theme.palette.text.primary
  },
  action: {
    padding: theme.spacing(1, 0)
  },
  arrowLeft: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    borderLeft: `1px solid ${theme.palette.grey[400]}`
  }
}));

export const topBarStyles = makeStyles()((theme) => ({
  avatar: {
    background: theme.customPalette.neutral[200],
    cursor: 'pointer',
    color: theme.palette.text.primary,
    height: theme.spacing(6),
    width: theme.spacing(6)
  },
  globalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
    boxShadow: theme.dimensions.navigationShadow,
    borderRadius: theme.dimensions.borderRadiusPanel,
    height: '100%',
    color: theme.palette.text.primary
  },
  globalHeaderWrapper: {
    zIndex: 1,
    height: theme.spacing(9),
    marginBottom: theme.spacing(3),
    background: theme.customPalette.neutral.main,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    borderRadius: theme.dimensions.borderRadiusPanel
  },
  globalHeader__burgerMenu: {
    cursor: 'pointer',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      display: 'none'
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'block',
      margin: 'auto 0'
    }
  },
  logo: {
    display: 'flex',
    maxHeight: 60
  },
  breadcrumbs: {
    fontSize: 13
  },
  globalHeader__menuWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  menu__chevron: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  lh1: {
    lineHeight: 1
  },
  menu__personalSettings: {
    color: 'red'
  },
  menu_link_wrapper: {
    display: 'flex',
    gap: theme.spacing(1)
  }
}));

export const iosToggleStyles = makeStyles()((theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1
  },
  thumb: {
    marginLeft: 2,
    marginTop: 2,
    width: 18,
    height: 18,
    backgroundColor: theme.palette.common.white
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.customPalette.neutral.light,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {
    color: theme.palette.common.white
  },
  focusVisible: {
    color: '#52d869',
    border: '6px solid #fff'
  }
}));

export const themeToggleStyles = makeStyles()((theme) => ({
  label: {
    padding: theme.spacing(2.5, 3)
  },
  toggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 3, 3)
  },
  icon: {
    width: 16,
    height: 16
  },
  button: {
    '&.MuiButton-contained': {
      boxShadow: 'none',
      backgroundColor: theme.palette.background.default
    },
    '&:first-of-type': {
      marginRight: theme.spacing(1)
    },
    '&:last-child': {
      marginLeft: theme.spacing(1)
    }
  },
  tooltip: {
    background: theme.customPalette.neutral[100],
    color: theme.palette.text.primary,
    opacity: 1,
    padding: theme.spacing(0.5, 1),
    fontSize: '1rem'
  },
  awake: {
    color: theme.palette.secondary.main
  },
  asleep: {
    color: theme.palette.text.primary
  }
}));

export const topBarMenuStyles = makeStyles()((theme) => ({
  itemsWrapper: {
    paddingTop: theme.spacing(3)
  },
  switcherWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3)
  },
  select: {
    margin: theme.spacing(0, 3, 2)
  },
  switcherLabel: {
    padding: `0 ${theme.spacing(4)} ${theme.spacing(2)}`
  },
  item: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    gap: theme.spacing(1),
    backgroundColor: 'transparent',
    '& svg': {
      width: theme.spacing(3),
      height: theme.spacing(3)
    },
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent'
    }
  },
  links: {
    borderBottom: `1px solid ${theme.customPalette.neutral[100]}`
  },
  menu__wrapper: {
    background: theme.customPalette.neutral.main,
    '& .MuiMenuItem-gutters': {
      padding: `0 ${theme.spacing(3)}`
    },
    '& .MuiList-padding': {
      padding: 0
    }
  },
  menuItems: {
    backgroundColor: theme.customPalette.neutral.main,

    '&:hover': {
      backgroundColor: theme.customPalette.neutral.main
    }
  }
}));

import React from 'react';
import { Field, FieldProps, ErrorMessage } from 'formik';

import Input, { InputType } from './input';

import { formStyles } from './componentStyles';

type InputWrapperType = FieldProps & InputType;

const InputWrapper = ({ field, className, ...restProps }: InputWrapperType) => {
  const { classes } = formStyles();

  return (
    <div className={className}>
      <Input {...field} {...restProps} />
      <div className={classes.error}>
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};
export default function FormInput(props: InputType) {
  return <Field component={InputWrapper} {...props} />;
}

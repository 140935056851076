import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import UserService from '../services/userService';
import { CreateInterviewArgs, Interview, InterviewState, Result } from '../models/interview';
import { reportsApi } from './reports.api';
import { SURVEY_STATES } from '../pages/telephoneSurveyPage/telephoneSurveyPageConstants';
import { createBaseQueryWithReAuth } from './utils';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_URL}/interview`,
  prepareHeaders: (headers) => {
    const token = UserService.getToken();

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

export const interviewApi = createApi({
  reducerPath: 'interview/api',
  refetchOnMountOrArgChange: true,
  baseQuery: createBaseQueryWithReAuth(baseQuery),
  endpoints: (build) => ({
    createInterview: build.mutation<Interview, CreateInterviewArgs>({
      query: ({ id, ...restProps }) => ({
        method: 'POST',
        url: `/survey/${id}`,
        body: JSON.stringify(restProps)
      })
    }),
    getInterviewState: build.query<InterviewState, string>({
      query: (id) => ({
        method: 'GET',
        url: `/state/${id}`
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data.state === SURVEY_STATES.FINISHED) {
            dispatch(reportsApi.util.invalidateTags(['ASSIGNED_REPORTS']));
          }
        } catch {
          /* empty */
        }
      }
    }),
    interviewResult: build.query<Result, string>({
      query: (id) => ({
        method: 'GET',
        url: `/${id}`
      })
    })
  })
});

export const { useInterviewResultQuery, useCreateInterviewMutation, useGetInterviewStateQuery } =
  interviewApi;

import React from 'react';
import { Switch } from '@mui/material';

import { iosToggleStyles } from './componentStyles';

const IosToggle = ({ ...props }) => {
  const { classes } = iosToggleStyles();
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      color="secondary"
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
};

export default IosToggle;

import React from 'react';
import cn from 'classnames';
import { Accordion as MuiAccordion, AccordionActions, AccordionProps } from '@mui/material';

import { Actions, ButtonType } from '.';
import { accordionStyles } from './componentStyles';

type Props = AccordionProps & {
  children: React.ReactElement[];
  className?: string;
  actionsProps?: {
    submit?: ButtonType;
    cancel?: ButtonType;
  };
};

export default function Accordion({ children, actionsProps, className, ...restProps }: Props) {
  const { classes } = accordionStyles();

  return (
    <MuiAccordion className={cn(classes.accordion, className)} {...restProps}>
      {children}
      {actionsProps && (
        <AccordionActions>
          <Actions actionsProps={actionsProps} />
        </AccordionActions>
      )}
    </MuiAccordion>
  );
}

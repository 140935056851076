import React from 'react';

import { Routes, Route } from 'react-router-dom';
import { AppLayout } from './components';
import { ROUTES } from './components/appConstants';
import {
  HomePage,
  UsersPage,
  UserGroupPage,
  SurveysPage,
  ReportCreationPage,
  WebSurveyPage,
  SurveySelectionPage,
  ResultPage,
  SettingsPage,
  TelephoneSurveyPage
} from './pages';

function App() {
  return (
    <AppLayout>
      <Routes>
        <Route path={ROUTES.HOME.index} element={<HomePage />} />
        <Route path={ROUTES.ADMIN.USERS} element={<UsersPage />} />
        <Route path={ROUTES.ADMIN.USERS_GROUP} element={<UserGroupPage />} />
        <Route path={ROUTES.ADMIN.SURVEYS} element={<SurveysPage />} />
        <Route path={ROUTES.ADMIN.REPORT_CREATION} element={<ReportCreationPage />} />
        <Route path={`${ROUTES.SURVEYS.WEB_SURVEY}/:id`} element={<WebSurveyPage />} />
        <Route path={ROUTES.SURVEYS.index} element={<SurveySelectionPage />} />
        <Route path={ROUTES.RESULT.RESULT_ITEM} element={<ResultPage />} />
        <Route path={ROUTES.SETTINGS.index} element={<SettingsPage />} />
        <Route path={`${ROUTES.SURVEYS.TELEPHONE_SURVEY}/:id`} element={<TelephoneSurveyPage />} />
      </Routes>
    </AppLayout>
  );
}

export default App;

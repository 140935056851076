import { createContext } from 'react';

import { ThemeConfig } from '../models/models';

type ValueType = {
  theme: string;
  themeConfig: ThemeConfig;
  setTheme: (arg: string) => void;
};

export const ThemeContext = createContext<ValueType>({
  theme: '',
  setTheme: () => {
    console.error('setTheme is not set');
  },
  themeConfig: {} as ThemeConfig
});

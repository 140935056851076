import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  MutationResponse,
  UpdateUserArgs,
  UpdateUserLanguageArgs,
  User,
  UserArgs,
  UserList
} from '../models/users';
import UserService from '../services/userService';
import { createBaseQueryWithReAuth } from './utils';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_URL}/users`,
  prepareHeaders: (headers) => {
    const token = UserService.getToken();

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

export const usersApi = createApi({
  reducerPath: 'users/api',
  tagTypes: ['USERS_LIST'],
  refetchOnMountOrArgChange: true,
  baseQuery: createBaseQueryWithReAuth(baseQuery),
  endpoints: (build) => ({
    usersList: build.query<UserList, { pageSize: number; page: number }>({
      query: ({ pageSize, page }) => ({
        method: 'GET',
        url: `/?pageSize=${pageSize}&page=${page}`
      }),
      providesTags: ['USERS_LIST']
    }),
    currentUser: build.query<User, void>({
      query: () => ({
        url: `/me`
      })
    }),
    createUser: build.mutation<User, UserArgs>({
      query: (body) => ({
        method: 'POST',
        body: JSON.stringify(body),
        url: `/`
      }),
      invalidatesTags: ['USERS_LIST']
    }),
    deleteUser: build.mutation<MutationResponse, string[]>({
      query: (body) => ({
        method: 'DELETE',
        body: JSON.stringify(body),
        url: `/`
      }),
      invalidatesTags: ['USERS_LIST']
    }),
    addUsersToGroup: build.mutation<MutationResponse, { users: string[]; user_groups: string[] }>({
      query: (body) => ({
        method: 'POST',
        body: JSON.stringify(body),
        url: `/user-groups`
      })
    }),
    updateUser: build.mutation<MutationResponse, UpdateUserArgs>({
      query: (body) => ({
        method: 'PUT',
        body: JSON.stringify(body),
        url: `/${body.id}`
      }),
      invalidatesTags: ['USERS_LIST']
    }),
    updateUserLanguage: build.mutation<MutationResponse, UpdateUserLanguageArgs>({
      query: (body) => ({
        method: 'POST',
        body: JSON.stringify(body),
        url: `/me/language`
      })
    })
  })
});

export const {
  useUpdateUserLanguageMutation,
  useUpdateUserMutation,
  useCurrentUserQuery,
  useAddUsersToGroupMutation,
  useDeleteUserMutation,
  useUsersListQuery,
  useCreateUserMutation
} = usersApi;

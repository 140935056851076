import React from 'react';
import cn from 'classnames';

import { placeholderStyles } from './componentStyles';

type Props = {
  title: string;
  description: string;
  icon?: React.ElementType;
};

export default function EmptyView({ title, description, icon: Icon }: Props) {
  const { classes } = placeholderStyles();

  return (
    <div className={classes.container}>
      {Icon && (
        <div>
          <Icon className={cn(classes.icon)} />
        </div>
      )}
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description}</div>
    </div>
  );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import UserService from '../services/userService';
import {
  CreateUserGroupArgs,
  MutationResponse,
  UpdateGroupArgs,
  UserGroup,
  UsersGroup
} from '../models/usersGroup';
import { createBaseQueryWithReAuth } from './utils';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_URL}/user-groups/`,
  prepareHeaders: (headers) => {
    const token = UserService.getToken();

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

export const usersGroupApi = createApi({
  reducerPath: 'usersGroup/api',
  tagTypes: ['USERS_GROUP_LIST'],
  refetchOnMountOrArgChange: true,
  baseQuery: createBaseQueryWithReAuth(baseQuery),
  endpoints: (build) => ({
    usersGroupList: build.query<UsersGroup, { pageSize: number; page: number }>({
      query: ({ pageSize, page }) => ({
        method: 'GET',
        url: `/?pageSize=${pageSize}&page=${page}`
      }),
      providesTags: ['USERS_GROUP_LIST']
    }),
    createUsersGroup: build.mutation<UserGroup, CreateUserGroupArgs>({
      query: (body) => ({
        method: 'POST',
        body: JSON.stringify(body),
        url: `/`
      }),
      invalidatesTags: ['USERS_GROUP_LIST']
    }),
    deleteUsersGroup: build.mutation<MutationResponse, string[]>({
      query: (body) => ({
        method: 'DELETE',
        body: JSON.stringify(body),
        url: `/`
      }),
      invalidatesTags: ['USERS_GROUP_LIST']
    }),
    updateUsersGroup: build.mutation<MutationResponse, UpdateGroupArgs>({
      query: (body) => ({
        method: 'PUT',
        body: JSON.stringify(body),
        url: `/${body.id}`
      }),
      invalidatesTags: ['USERS_GROUP_LIST']
    })
  })
});

export const {
  useUpdateUsersGroupMutation,
  useCreateUsersGroupMutation,
  useUsersGroupListQuery,
  useLazyUsersGroupListQuery,
  useDeleteUsersGroupMutation
} = usersGroupApi;

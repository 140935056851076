import React, { MouseEvent } from 'react';
import cn from 'classnames';
import { Box, IconButton } from '@mui/material';
import { CaretLeft, CaretRight } from '@carbon/icons-react';

import { tablePaginationActionStyles } from './componentStyles';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export default function TablePaginationActions(props: TablePaginationActionsProps) {
  const { classes } = tablePaginationActionStyles();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }} className={classes.actions}>
      <div className={cn(classes.arrowLeft, classes.action)}>
        <IconButton
          className={classes.button}
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page">
          <CaretLeft />
        </IconButton>
      </div>
      <div className={classes.action}>
        <IconButton
          className={classes.button}
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page">
          {<CaretRight />}
        </IconButton>
      </div>
    </Box>
  );
}

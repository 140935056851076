import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const pdfService = createApi({
  reducerPath: 'pdfService/api',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PDF_SERVICE_URL,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
    }
  }),
  endpoints: (build) => ({
    convertHtmlToPdf: build.mutation<
      { url: string },
      {
        html: string;
        css: string;
        format: string;
        logo: string;
        filename: string;
      }
    >({
      query: (body) => ({
        method: 'POST',
        url: '',
        body: JSON.stringify(body)
      })
    })
  })
});

export const { useConvertHtmlToPdfMutation } = pdfService;

import React, { useState } from 'react';

import SideNavigation from './sideNavigation';
import TopBar from './topBar';
import useNavigationList from '../hooks/useNavigationList';

import { appLayoutStyles } from './componentStyles';

type AppLayoutType = {
  children: React.ReactElement;
};

export default function AppLayout({ children }: AppLayoutType) {
  const { classes } = appLayoutStyles();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const data = useNavigationList();

  const handleHamburgerClick = () => {
    setIsMobileMenuOpen((prevState) => {
      return !prevState;
    });
  };

  return (
    <div className={classes.application} id="application">
      <TopBar handleHamburgerClick={handleHamburgerClick} />
      <div className={classes.contentWrapper}>
        <SideNavigation
          isMobileMenuOpen={isMobileMenuOpen}
          className={classes.navigation}
          menuMainItems={data}
        />
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
}

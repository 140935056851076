import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from '@reduxjs/toolkit/dist/query/react';
import UserService from '../services/userService';

export const createBaseQueryWithReAuth = (
  baseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    NonNullable<unknown>,
    FetchBaseQueryMeta
  >
) => {
  const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
  ) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
      try {
        await UserService.updateToken();
        result = await baseQuery(args, api, extraOptions);
      } catch {
        UserService.doLogout();
      }
    }

    return result;
  };

  return baseQueryWithReauth;
};

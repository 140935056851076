import Keycloak from 'keycloak-js';

const keycloak = new Keycloak('/keycloak.json');

const initKeycloak = (onAuthenticatedCallback: (error?: string) => void) => {
  keycloak
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
      checkLoginIframe: false
    })
    .then((authenticated: boolean) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        doLogin();
      }
    })
    .catch((reason) => {
      onAuthenticatedCallback(reason?.error);
    });
};

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = async () => {
  await keycloak.updateToken(5).catch(doLogin);
};

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const getMail = () => keycloak.tokenParsed?.email;

const getFamilyName = () => keycloak.tokenParsed?.family_name;

const getGivenName = () => keycloak.tokenParsed?.given_name;

const getTokenParsed = () => keycloak.tokenParsed;

const hasRole = (roles: string[]) => roles.some((role: string) => keycloak.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getMail,
  getFamilyName,
  getGivenName,
  getTokenParsed
};

export default UserService;

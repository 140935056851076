import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { TreeView, TreeItem, SimpleTreeView } from '@mui/x-tree-view';
import { ChevronDown, ChevronUp } from '@carbon/icons-react';

import { sideNavigationStyles } from './componentStyles';

interface RenderTree {
  id: string;
  name: string;
  link?: string;
  category?: string;
  withTabIndex?: boolean;
  children?: readonly RenderTree[];
  icon?: React.JSXElementConstructor<React.ReactNode>;
}

type SideNavigationType = {
  isMobileMenuOpen: boolean;
  menuMainItems?: RenderTree[];
  menuAdditionalItems?: RenderTree[];
  className?: string;
};

const renderTree = (nodes: RenderTree, pathname: string, classes: Record<string, string>) => {
  const isLinkActive = nodes.link === pathname;

  const tabIndex = nodes.withTabIndex ? {} : { tabIndex: -1 };

  const labelItem = <span>{nodes.name}</span>;
  const treeItem = (
    <TreeItem
      itemId={nodes.id}
      label={labelItem}
      className={cn({
        [classes.active]: isLinkActive,
        [classes.link]: !isLinkActive,
        [classes.activeCategory]: nodes.category && pathname.includes(nodes.category)
      })}
      slots={{ expandIcon: ChevronDown, collapseIcon: ChevronUp }}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node, pathname, classes))
        : null}
    </TreeItem>
  );

  return nodes.link ? (
    <Link key={nodes.id} {...tabIndex} to={nodes.link}>
      {treeItem}
    </Link>
  ) : (
    <div key={nodes.id}>{treeItem}</div>
  );
};

function SideNavigation({
  isMobileMenuOpen,
  menuMainItems,
  menuAdditionalItems,
  className
}: SideNavigationType) {
  const { classes } = sideNavigationStyles();
  const { pathname } = useLocation();
  const expanded = menuMainItems?.reduce((acc, current) => {
    if (current.category && pathname.includes(current.category)) {
      acc.push(current.id);
    }

    return acc;
  }, [] as Array<string>);

  const expandedItems = useRef(expanded);

  return (
    <div
      className={cn(classes.navigation, className, {
        [classes.showNav]: isMobileMenuOpen
      })}>
      {Boolean(menuMainItems) && (
        <SimpleTreeView defaultExpandedItems={expandedItems.current}>
          {menuMainItems?.map((menuItem) => {
            return renderTree(menuItem, pathname, classes);
          })}
        </SimpleTreeView>
      )}
      {Boolean(menuAdditionalItems) && (
        <TreeView>
          {menuAdditionalItems?.map((menuItem) => {
            return renderTree(menuItem, pathname, classes);
          })}
        </TreeView>
      )}
    </div>
  );
}

export default SideNavigation;

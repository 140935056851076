import React, { useState, useEffect, MouseEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { Button } from '.';
import useMuiTheme from '../hooks/useMuiTheme';
import { ROUTES } from './appConstants';
import TopBarMenu from './topBarMenu';
import Breadcrumbs from './breadcrumbs';
import UserService from '../services/userService';

import { topBarStyles } from './componentStyles';

type Props = {
  handleHamburgerClick: () => void;
};

const getFirstChar = (value: string) => {
  return value?.charAt(0)?.toUpperCase() || '';
};

const TopBar = ({ handleHamburgerClick }: Props) => {
  const [initials, setInitials] = useState('');
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { classes } = topBarStyles();
  const muiTheme = useMuiTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  useEffect(() => {
    // Set initials
    setInitials('MM');
    const firstChar = getFirstChar(UserService.getGivenName());
    const secondChar = getFirstChar(UserService.getFamilyName());
    if (
      UserService.getGivenName() !== UserService.getFamilyName() &&
      firstChar.length > 0 &&
      secondChar.length > 0
    ) {
      // 1. Check by given and family name
      setInitials(`${firstChar}${secondChar}`);
    } else if (
      UserService.getGivenName() === UserService.getFamilyName() &&
      firstChar.length > 0 &&
      secondChar.length > 0
    ) {
      // 2. If given and family name are the same use first char of given name
      setInitials(`${firstChar}`);
    } else if (UserService.getMail()) {
      // 3. Check by given mail
      const splittedAdress = UserService.getMail()?.split('@');
      const splittedPrefix = splittedAdress[0].split('.');
      const initials = splittedPrefix[1]
        ? `${getFirstChar(splittedPrefix[0])}${getFirstChar(splittedPrefix[1])}`
        : `${splittedPrefix[0]?.substring(0, 2)}`;
      setInitials(initials);
    } else {
      // 4. Show nothing/user avatar
      setInitials('');
    }
  }, []);
  const backgroundSrc = muiTheme.customComponents.topBar.backgroundSrc;
  const backgroundImage = backgroundSrc ? `url('${backgroundSrc}')` : 'none';

  return (
    <div
      className={classes.globalHeaderWrapper}
      style={{ backgroundImage }}
      data-testid="global-header-wrapper">
      <div className={classes.globalHeader}>
        <div className={classes.menu_link_wrapper}>
          <div className={classes.globalHeader__burgerMenu}>
            <Button icon={<MenuIcon />} variant="text" onClick={handleHamburgerClick} />
          </div>
          <Link to={ROUTES.HOME.index} className={classes.logo}>
            <img src={muiTheme?.images?.logo} alt="Logo" />
          </Link>
        </div>
        {!isMobile && pathname !== ROUTES.HOME.index && (
          <div className={classes.breadcrumbs}>
            <Breadcrumbs />
          </div>
        )}
        <div className={classes.globalHeader__menuWrapper}>
          <div>
            <Avatar className={classes.avatar} onClick={handleClick}>
              <span className={classes.lh1}>{initials.toUpperCase()}</span>
            </Avatar>
          </div>
          <div className={classes.menu__chevron}>
            {menuOpen && <TopBarMenu onClose={handleClose} anchorEl={anchorEl} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;

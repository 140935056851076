import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import UserService from '../services/userService';
import { AssignGroupsToReportsArgs, AssignUsersToReportsArgs } from '../models/surveys';
import { CreateReport, RawReport, Report } from '../models/reports';
import { usersGroupApi } from './usersGroup.api';
import { usersApi } from './users.api';
import { createBaseQueryWithReAuth } from './utils';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BACKEND_URL}/reports`,
  prepareHeaders: (headers) => {
    const token = UserService.getToken();

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

export const reportsApi = createApi({
  reducerPath: 'reports/api',
  tagTypes: ['ASSIGNED_REPORTS'],
  refetchOnMountOrArgChange: true,
  baseQuery: createBaseQueryWithReAuth(baseQuery),
  endpoints: (build) => ({
    reportsList: build.query<Report[], void>({
      query: () => ({
        method: 'GET',
        url: '/'
      })
    }),
    createReport: build.query<Report[], CreateReport>({
      query: (body) => ({
        method: 'POST',
        url: '/',
        body: JSON.stringify(body)
      })
    }),
    result: build.query<RawReport, string>({
      query: (id) => ({
        method: 'GET',
        url: `/${id}/users/me/raw-responses`
      })
    }),
    resultByUser: build.query<RawReport, { reportId: string; userId: string }>({
      query: ({ reportId, userId }) => ({
        method: 'GET',
        url: `/${reportId}/users/${userId}/raw-responses`
      })
    }),
    assignedReports: build.query<Report[], void>({
      query: () => ({
        method: 'GET',
        url: '/my/statuses'
      }),
      providesTags: ['ASSIGNED_REPORTS']
    }),
    assignReportsToUsers: build.mutation<{ message: string }, AssignUsersToReportsArgs>({
      query: (body) => ({
        method: 'POST',
        url: '/users',
        body: JSON.stringify(body)
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(usersApi.util.invalidateTags(['USERS_LIST']));
        } catch {
          /* empty */
        }
      }
    }),
    assignReportsToGroups: build.mutation<{ message: string }, AssignGroupsToReportsArgs>({
      query: (body) => ({
        method: 'POST',
        url: '/user-groups',
        body: JSON.stringify(body)
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(usersGroupApi.util.invalidateTags(['USERS_GROUP_LIST']));
        } catch {
          /* empty */
        }
      }
    })
  })
});

export const {
  useLazyResultByUserQuery,
  useLazyCreateReportQuery,
  useResultQuery,
  useAssignedReportsQuery,
  useAssignReportsToGroupsMutation,
  useAssignReportsToUsersMutation,
  useReportsListQuery,
  useLazyReportsListQuery
} = reportsApi;

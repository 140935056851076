import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { InputBase, InputBaseProps, InputLabel } from '@mui/material';

import { inputStyles } from './componentStyles';

export type InputType = Omit<InputBaseProps, 'size'> & {
  label?: string;
  size?: 1 | 2;
  icon?: React.ElementType;
};

export default function Input({ label, size = 2, className, icon: Icon, ...props }: InputType) {
  const [t] = useTranslation();
  const { classes } = inputStyles();

  return (
    <div className={className}>
      {label && (
        <div>
          <InputLabel className={classes.label}>{label}</InputLabel>
        </div>
      )}
      <InputBase
        placeholder={t('COMMON.INPUT_PLACEHOLDER')}
        startAdornment={Icon && <Icon className={classes.icon} />}
        {...props}
        className={cn(classes.input, classes[`size${size}`])}
      />
    </div>
  );
}

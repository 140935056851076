import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbComponentProps } from 'use-react-router-breadcrumbs';

import { ROUTES } from '../components/appConstants';

const BreadcrumbsElement = ({ routeName }: BreadcrumbComponentProps) => {
  return <span>{routeName as React.ReactNode}</span>;
};

export default function useRoutesList() {
  const [t, i18n] = useTranslation();

  return useMemo(
    () => [
      {
        path: ROUTES.HOME.index,
        breadcrumb: BreadcrumbsElement,
        props: { routeName: t('COMMON.NAVIGATION.HOME') }
      },
      {
        path: ROUTES.ADMIN.index,
        breadcrumb: BreadcrumbsElement,
        props: { routeName: t('COMMON.NAVIGATION.ADMIN') }
      },
      {
        path: ROUTES.ADMIN.USERS,
        breadcrumb: BreadcrumbsElement,
        props: { routeName: t('COMMON.NAVIGATION.USERS') }
      },
      {
        path: ROUTES.ADMIN.USERS_GROUP,
        breadcrumb: BreadcrumbsElement,
        props: { routeName: t('COMMON.NAVIGATION.USERS_GROUP') }
      },
      {
        path: ROUTES.ADMIN.SURVEYS,
        breadcrumb: BreadcrumbsElement,
        props: { routeName: t('COMMON.NAVIGATION.SURVEYS') }
      },
      {
        path: ROUTES.SURVEYS.WEB_SURVEY,
        breadcrumb: BreadcrumbsElement,
        props: { routeName: t('WEB_SURVEY.TITLE') }
      },
      {
        path: ROUTES.SURVEYS.TELEPHONE_SURVEY,
        breadcrumb: BreadcrumbsElement,
        props: { routeName: t('TELEPHONE_SURVEY.TITLE') }
      },
      {
        path: ROUTES.SURVEYS.index,
        breadcrumb: BreadcrumbsElement,
        props: { routeName: t('COMMON.NAVIGATION.SURVEYS') }
      },
      {
        path: ROUTES.RESULT.index,
        breadcrumb: BreadcrumbsElement,
        props: { routeName: 'Result' }
      },
      {
        path: ROUTES.SETTINGS.index,
        breadcrumb: BreadcrumbsElement,
        props: { routeName: t('COMMON.SETTINGS_LABEL') }
      }
    ],
    [i18n.language]
  );
}

import { CircularProgress, CircularProgressProps } from '@mui/material';
import React from 'react';
import cn from 'classnames';

import { loaderStyles } from './componentStyles';

type PropsType = CircularProgressProps & {
  fullScreen?: boolean;
};

const Loader = ({ fullScreen, ...restProps }: PropsType) => {
  const { classes } = loaderStyles();

  return (
    <div className={cn(classes.container, { [classes.fullScreen]: fullScreen })}>
      {fullScreen && <div className={classes.background} />}
      <CircularProgress
        {...restProps}
        className={cn(
          {
            [classes.fullScreenSpinner]: fullScreen
          },
          restProps.className
        )}
      />
    </div>
  );
};

export default Loader;

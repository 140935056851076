import React from 'react';
import cn from 'classnames';
import { Modal as MuiModal, ModalProps, IconButton } from '@mui/material';
import { Close } from '@carbon/icons-react';

import { Button, ButtonType } from '.';

import { modalStyles } from './componentStyles';

type ModalType = ModalProps & {
  children: React.ReactElement;
  onClose: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  actionsProps?: {
    submit?: ButtonType;
    cancel?: ButtonType;
  };
};

export default function Modal({
  children,
  actionsProps,
  className,
  onClose,
  ...restProps
}: ModalType) {
  const { classes } = modalStyles();

  return (
    <MuiModal
      {...restProps}
      onClose={onClose}
      BackdropProps={{ className: classes.backDrop }}
      className={classes.container}>
      <div className={cn(classes.modal, className)}>
        <IconButton className={classes.close} onClick={onClose}>
          <Close />
        </IconButton>
        {children}
        {actionsProps && (
          <div className={classes.actions}>
            {actionsProps.cancel && <Button {...actionsProps.cancel} />}
            {actionsProps.submit && <Button {...actionsProps.submit} />}
          </div>
        )}
      </div>
    </MuiModal>
  );
}
